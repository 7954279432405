import React from "react";


import { Helmet } from "react-helmet";

import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Presentation Page</title>
        <meta property="og:title" content="Presentation Page" />
      </Helmet>
      <div className="mobile-header">
      <div data-role="Header" id="page-header" className="home-navbar-container"  style={{backgroundImage: 'url(' + require("../asset/headerimage.png")+ ')',  backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',}}>
        <div className="home-navbar">
          <div className="home-links-container">
            <a href="#services" className="home-link Anchor">
              services
            </a>
            <a href="#about-us" className="home-link01 Anchor">
              About Us
            </a>
            <a href="#contact" className="home-link02 Anchor">
              contact
            </a>
          </div>
          <div className="home-cta-container">
            <div data-role="BurgerMenu" className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-top">
              <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-image1"
              />
              <div data-role="CloseMobileMenu" className="home-container01">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-mid">
              <div className="home-links-container1">
                <a
                  href="#services"
                  // onclick="resetHref()"
                  className="home-link03 Anchor"
                >
                  services
                </a>
                <a href="#about-us" className="home-link04 Anchor">
                  About Us
                </a>
                <a href="#contact" className="home-link05 Anchor">
                  contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="home-hero">
        <div className="home-container02">
          <h1 className="home-heading">SMKR Consultancy</h1>
          <div className="home-container03">
            <div className="home-hero-text-container">
              <span className="home-text">
                <span>
                  SMKR Consultancy provides a niche set of services addressing
                  the problems faced by organizations to get the right-fit
                  skills to support their business and know-how in setting up
                  core IT teams/Center of Excellences on niche IT domain areas.
                </span>
                <br></br>
                <br className="home-text003"></br>
                <span className="home-text004">Core Services:</span>
                <br></br>
                <span>IT HR Requirement</span>
                <br></br>
                <span>IT Advisory &amp; Consulting</span>
                <br></br>
                <span>IT Delivery and QA</span>
                <br></br>
              </span>
            </div>
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHx0ZWNobm9sb2d5fGVufDB8fHx8MTY3Mjc3MzQxNQ&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1500"
              className="home-image2"
            />
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div id="about-us" className="home-about-us">
        <div className="home-heading-container">
          <h2 className="home-heading1 Section-Heading">About Us</h2>
          <span className="home-secondary-text Section-Text">
            {" "}
            Get to know us better
          </span>
        </div>
        <div className="home-text-container">
          <span className="home-text012">
            <span>
              SMKR Consultancy incorprated by seasoned professionals in HR &amp;
              IT Consultancy, together with over 35 years of experience in
              Technology sector providing, boutique recruitment services,
              advisory, consulting on niche software engineering areas and
              incubating &amp; scaling value driven Centers Of Excellence (CoE).
            </span>
            <br className="Section-Text"></br>
            <br></br>
            <br></br>
          </span>
        </div>

        <a href="#page-header" className="home-link06">
          <svg viewBox="0 0 1024 1024" className="home-icon04">
            <path d="M512 32l-480 480h288v512h384v-512h288z"></path>
          </svg>
        </a>
      </div>
      <div className="home-section-separator1"></div>
      <div id="services" className="home-services">
        <h2 className="home-text017" id="services">
          <span className="Section-Heading">Our Services</span>
          <br></br>
        </h2>
        <div className="home-service-card">
          <div className="home-card-content">
            <h3 className="home-text020 BigCard-Heading">IT HR Recruitment</h3>
            <span className="home-text021">
              <span className="home-text022 Card-Text">
                Boutique Recruitment Services across Information Technology (IT)
                domain.
              </span>
              <br></br>
              <br></br>
            </span>
            <a href="#features1" className="home-link07 button Anchor">
              READ MORE
            </a>
          </div>
        </div>
        <div className="home-service-card1">
          <div className="home-card-content1">
            <h3 className="home-text025 BigCard-Heading">
              <span>IT Advisory</span>
              <span> &amp; </span>
              <span>Consulting</span>
            </h3>
            <span className="home-text029 Card-Text">
              Advisory &amp; Consulting services on Architecture, Software
              Engineering, DevOps, UX, Mobile App development
            </span>
            <a href="#features2" className="home-link08 button Anchor">
              READ MORE
            </a>
          </div>
        </div>
        <div className="home-service-card2">
          <div className="home-card-content2">
            <h3 className="home-text030 BigCard-Heading">
              <span>
                IT Delivery
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>&amp; </span>
              <span>
                QA
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </h3>
            <span className="home-text034">
              <span className="home-text035">Ìncubate &amp; Operate CoE </span>
              <br className="home-text036"></br>
              <span className="home-text037">
                IT Delivery, QA,
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text038">DevOp</span>
              <span className="home-text039">s</span>
              <br className="home-text040"></br>
            </span>
            <a href="#features3" className="home-link09 Anchor button">
              READ MORE
            </a>
          </div>
        </div>
      </div>
      <div className="home-section-separator2"></div>
      <div className="home-container04">
        <div id="features1" className="home-it-hr-recruitment">
          <div className="home-heading-container1">
            <h2 className="home-text041 Section-Heading">IT HR Recruitment</h2>
          </div>
          <div className="home-cards-container">
            <div className="home-feature-card">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1573164713714-d95e436ab8d6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxpdCUyMGlufGVufDB8fHx8MTY3Mjg0NDE1OA&amp;ixlib=rb-4.0.3&amp;w=300"
                className="home-image3"
              />
              <span className="home-heading2 BigCard-Heading">Why</span>
              <span className="home-text042">
                <span>
                  We know the IT industry as we are a part of IT. We assure
                  first time screen select.
                </span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="home-feature-card1">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1616587894289-86480e533129?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxpbnRlcnZpZXd8ZW58MHx8fHwxNjcyODQ0MjAx&amp;ixlib=rb-4.0.3&amp;w=200"
                className="home-image4"
              />
              <span className="home-heading3 BigCard-Heading">What</span>
              <span className="home-text046">
                <span className="home-text047">
                  Drastically reduce hiring cycle time.
                </span>
                <br className="home-text048"></br>
                <span className="home-text049">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="home-text050"></br>
                <span className="home-text051">
                  We take ownership to present you the right talent, resulting
                  in lower overall accquisition costs.
                </span>
                <br className="home-text052"></br>
                <span className="home-text053">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="home-text054"></br>
                <span className="home-text055">
                  Faster GTM and lower onboarding cost
                </span>
                <span className="home-text056">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="home-feature-card2">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxpbnRlcnZpZXd8ZW58MHx8fHwxNjcyODQ0MjAx&amp;ixlib=rb-4.0.3&amp;w=300"
                className="home-image5"
              />
              <span className="home-heading4 BigCard-Heading">How</span>
              <span className="home-text057">
                <span>
                  Proven track record of sourcing right fit profiles using
                  proprietary outcome based talent search methodology.
                </span>
                <br className="Card-Text"></br>
                <br></br>
                <span>
                  Inhouse &amp; empanelled technical experts across domains to
                  evaluate and screen talent.
                </span>
              </span>
            </div>
            <a href="#page-header" className="home-link10">
              <svg viewBox="0 0 1024 1024" className="home-icon06">
                <path d="M512 32l-480 480h288v512h384v-512h288z"></path>
              </svg>
            </a>
          </div>
          <div className="home-heading-container2">
            <h2 className="home-text062 Section-Heading">
              <br></br>
              <br></br>
              <span>Candidate Service</span>
            </h2>
          </div>
          <span className="home-text066">
            <span className="home-text067">
              Potential Candidates are at the core of the recruitment journey
              for any organization. At SMKR, we offer candidate centric services
              to help the potential candidates to put their best foot forward in
              their career growth journey
            </span>
            <br></br>
            <span>
              SMKR Consultancy, offer personalized services to Candidates on:
            </span>
            <br></br>
            <span>1. Career Counselling</span>
            <br></br>
            <span>2. Resume writing and review</span>
            <br></br>
            <span>3. Negotiation tips</span>
          </span>
          <a href="#page-header" className="home-link11">
            <svg viewBox="0 0 1024 1024" className="home-icon08">
              <path d="M512 32l-480 480h288v512h384v-512h288z"></path>
            </svg>
          </a>
        </div>
      </div>
      <div id="features2" className="home-it-advisory-consultancy">
        <div className="home-heading-container3">
          <h2 className="home-text076 Section-Heading">
            IT Advisory &amp; Consultancy
          </h2>
          <span className="home-text077">
            <br></br>
            <br></br>
          </span>
        </div>
        <span className="home-text080">
          <span>
            IT Advisory &amp; Consultancy services across IT Architecture,
            Software Engineering, Performance Engineering, User Experience and
            related areas.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <br></br>
          <span>
            This is supported by a wide network of empanelled consultants who
            are industry practitioners 
          </span>
        </span>
        <a href="#page-header" className="home-link12">
          <svg viewBox="0 0 1024 1024" className="home-icon10">
            <path d="M512 32l-480 480h288v512h384v-512h288z"></path>
          </svg>
        </a>
      </div>
      <div className="home-section-separator3"></div>
      <div id="features3" className="home-it-delivery-qa">
        <div className="home-heading-container4">
          <h2 className="home-text084 Section-Heading">IT Delivery &amp; QA</h2>
        </div>
        <span className="home-text085">
          <span>
            SMKR can help organizations by conducting due diligence followed by
            strategy to incubate tailored Centres of Excellence (CoE) for
            various units across IT Delivery
          </span>
          <br className="home-text087"></br>
          <br className="home-text088"></br>
          <span className="home-text089">Key areas of Expertise:</span>
          <br className="home-text090"></br>
          <span>
            1. Development &amp; delivery of custom web-based &amp; mobile
            applications.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <br className="home-text092"></br>
          <span>
            2. QA verticals for Functional Testing, Test case Automation,
            Performance Testing and Security Testing.
          </span>
          <br className="home-text094"></br>
          <span>
            3. DevOps, Software Engineering and Performance Engineering
          </span>
          <br className="home-text096"></br>
          <br className="home-text097"></br>
          <span>
            The value-centric models benefit both the services organization and
            their customers.
          </span>
          <br className="home-text099"></br>
          <br className="home-text100"></br>
          <br></br>
          <br></br>
        </span>
        <a href="#page-header" className="home-link13">
          <svg viewBox="0 0 1024 1024" className="home-icon12">
            <path d="M512 32l-480 480h288v512h384v-512h288z"></path>
          </svg>
        </a>
      </div>
      <div className="home-section-separator4"></div>
      <div className="home-container05">
        {/* <div>
        </div> */}
        <div id="contact" className="home-contact">
          <div className="home-content-container">
        <h1 className="home-text103 Section-Heading">Contact Us</h1>
            <div className="home-container06">
              <a href="mailto:reachus@smkr.in?subject=" className="home-link14">
                <svg viewBox="0 0 1024 1024" className="home-icon14">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
              <div className="home-container07">
                <div className="home-container08">
                  <div className="home-email">
                    <a
                      href="mailto:reachus@smkr.in?subject="
                      className="home-text104"
                    >
                      <span>reachus @smkr.in </span>
                      <br></br>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-container09">
              <a
                href="https://wa.me/918296297657"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link15"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon16"
                >
                  <path d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"></path>
                </svg>
              </a>
              <div className="home-container10">
                <div className="home-phone"></div>
              </div>
              <div className="home-container07">
                <div className="home-container08">
                  <div className="home-email">
            <a
              href="https://wa.me/918296297657"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link16"
            >
              +91-829 629 SMKR
            </a>
       </div>
       </div>
       </div>
            </div>
            <div className="home-container09">
          <a
            href="https://www.linkedin.com/in/smkrc/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link18"
          >
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon20">
              <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
            </svg>
          </a>
          <div className="home-container07">
                <div className="home-container08">
                  <div className="home-email">
          <a
            href="https://www.linkedin.com/in/smkrc/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link19"
          >
            https://www.linkedin.com/in/smkrc/
          </a>
          </div>
          </div>
          </div>
          </div>
          </div>
         {/* <div> */}
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxjb250YWN0JTIwdXN8ZW58MHx8fHwxNjcyODQ0ODMw&amp;ixlib=rb-4.0.3&amp;w=1000"
            loading="eager"
            className="home-image6 contact-image-mobile"
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
